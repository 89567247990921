import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { GET_ISLOGIN } from '@/common/consts/mutation-types'
import { ElMessage } from 'element-plus'
import request from './common/request'
import store2 from './store/index'
import useUserStore from "@/store/modules/user"

// import zhCn from "element-plus/lib/locale/lang/zh-cn";

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(store)
  .use(store2)
  .use(router).use(ElementPlus
    //, { locale: zhCn }
    ).mount('#app')

document.title = '佳背英语'

let userStore = useUserStore();
const token = localStorage.getItem("access-token");
if (token) {
  userStore.initUser();
}

interface AnnouncementModel {
  content: string,
}

request.get<AnnouncementModel>('/Announcement/Get').then((response) => {
  if (response.data.content != null && response.data.content != '') {
    ElMessage({
      message: response.data.content,
      type: "success",
    });
  }
});


const currentVerisons = '1'
const versions = localStorage.getItem('versions')
if (!versions || versions != currentVerisons) {
  localStorage.clear()
  localStorage.setItem('versions', currentVerisons)
}