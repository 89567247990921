export interface LoginInfo {
    Id: string,
    unique_name: string,
    NickName: string,
    Type: number,
    role: string[],
    isManage: boolean,
}

export interface pageQuery {
    pageIndex: number,
    pageSize: number,
}

export interface pageList<T> {
    pageIndex: number,
    pageSize: number,
    totalCount: number,
    totalPages: number,
    items: T[],
    hasPrevPages: boolean,
    hasNextPages: boolean
}

export interface PageResponse<T> {
    statusCode: number,
    data: pageList<T>,
    succeeded: boolean,
    errors: any,
    extras: any,
    timestamp: number

}

export interface DataResponse<T> {
    statusCode: number,
    data: T,
    succeeded: boolean,
    errors: any,
    extras: any,
    timestamp: number

}

export const GlobalValue = {
    pageQuery() {
        return {
            pageIndex: 1,
            pageSize: 50,
        };
    },
    pageList() {
        return {
            pageIndex: 0,
            pageSize: 0,
            totalCount: 0,
            totalPages: 0,
            items: [],
            hasPrevPages: false,
            hasNextPages: false
        };
    }
}

export const value={
    LoginInfo():LoginInfo{
        return{
            Id: '',
            unique_name: '',
            NickName: '',
            Type: 0,
            role: [],
            isManage: false,
        }
    }
}