/*
import useUserStore from "@/store/modules/user";
let userStore = useUserStore();
*/

import { value } from "@/common/models/Airticle";
import { value as value1, LoginInfo } from "@/libs/type";
import { defineStore } from "pinia";
import { computed, ref } from "vue";


function parseJwt(token: string): LoginInfo {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const tokenInfo = JSON.parse(jsonPayload);
    let info: LoginInfo = {
        Id: String(tokenInfo.Id),
        unique_name: String(tokenInfo.unique_name),
        NickName: String(tokenInfo.NickName),
        Type: tokenInfo.Type,
        role: Array.from(tokenInfo["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]),
        isManage: false,
    };

    info.isManage = info.role.includes("manage");
    return info;
}

let useUserStore = defineStore("user", () => {
    let user = ref(value1.LoginInfo());
    let isLogin = computed(() => {
        return user.value.Id != ''
    });
    const hideInfo = ref(false);
    const isApp = ref(false);
    return {
        user,
        isLogin,
        hideInfo,
        isApp,
        initUser() {
            let token = window.localStorage.getItem(
                "access-token"
            );
            if (token)
                user.value = parseJwt(token);
        },
        quit() {
            user.value = value1.LoginInfo();
            localStorage.removeItem('access-token');
        }
    }
});
export default useUserStore;