/*
import useOldStoreStore from "@/store/modules/oldStore";
let oldStoreStore = useOldStoreStore();
*/

import { THeadConfig } from "@/common/models/Airticle";
import { defineStore } from "pinia";
let useOldStoreStore = defineStore("oldStore", () => {
    const pageConfig: THeadConfig = { refreshNum: 0, candidateNum: 5, encrypt: false, recite: false, enableNumKey: false, enableRepetition: false }

    return {
        //isLogin: false,
        DefaultChoice: "",
        HideInfo: false,
        PageConfig: pageConfig,
        Query: '',
        audioTime: 0,
        isPlaying: false,
    }
});

export default useOldStoreStore;
