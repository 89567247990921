import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/practiceList',
  },
  {
    path: '/practice',
    name: 'practice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/practice/practice.vue'),
  },
  {
    path: '/practiceList',
    name: 'practiceList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/practiceList/practiceList.vue')
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/dictionary',
    name: 'dictionary',
    component: () => import('../views/Dictionary/dictionary.vue')
  },
  {
    path: '/dictionaryMain',
    name: 'dictionaryMain',
    component: () => import('../views/Dictionary/dictionaryMain.vue')
  },
  {
    path: '/head',
    name: 'head',
    component: () => import('../views/practice/components/pagehead.vue')
  },
  {
    path: '/content',
    name: 'content',
    component: () => import('../views/practice/content.vue')
  },
  {
    path: '/contentAudio',
    name: 'contentAudio',
    component: () => import('../views/practice/contentAudio.vue')
  },
  {
    path: '/memorizeWords',
    name: 'memorizeWords',
    component: () => import('../views/MemorizeWords/MemorizeWords.vue')
  },
  {
    path: '/Survey',
    name: 'Survey',
    component: () => import('../views/Survey/Survey.vue')
  },
  {
    path: '/manage',
    name: 'manage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/manage/manage.vue'),
    redirect: '/manage/AirticleList',
    children: [
      { path: 'AirticleList', component: () => import('../views/AirticleList/AirticleList.vue'), },
      { path: 'SeriesList', component: () => import('../views/SeriesList/SeriesList.vue'), },
      { path: 'AirticleEdit', component: () => import('../views/AirticleEdit/AirticleEdit.vue'), },
      { path: 'InviteCode', component: () => import('../views/InviteCode/InviteCode.vue'), },
      { path: 'Grammar', component: () => import('../views/AirticleEdit/Grammar.vue'), },
      { path: 'Announcement', component: () => import('../views/manage/Announcement.vue'), },
      { path: 'SurveyView', component: () => import('../views/Survey/SurveyView.vue'), },
      { path: 'Sentence', component: () => import('../views/manage/sentence/Sentence.vue'), },
    ]
  },
  {
    path: '/UserManage',
    name: 'UserManage',
    component: () => import('../views/UserManage/UserManageV.vue'),
    redirect: '/UserManage/user',
    children: [
      { path: 'user', component: () => import('../views/UserManage/userV.vue') },
      { path: 'userPassword', component: () => import('../views/UserManage/userPasswordV.vue') },
      { path: 'transaction', component: () => import('../views/UserManage/transactionV.vue') },
      { path: 'transactionEdit', component: () => import('../views/UserManage/components/transactionEdit.vue') },
      { path: 'NewOrder', component: () => import('../views/UserManage/NewOrderV.vue') },
    ]
  },
  {
    path: '/sentence',
    name: 'sentence',
    component: () => import('../views/sentence/sentence.vue')
  },
  {
    path: '/question',
    name: 'question',
    component: () => import('../views/practise/manage/questions.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
