import router from '@/router';
import axios, { AxiosResponse } from 'axios';
import { ElMessage } from 'element-plus'
import { GET_ISLOGIN, DefaultChoice } from '@/common/consts/mutation-types'
import useUserStore from "@/store/modules/user";
import useOldStoreStore from "@/store/modules/oldStore";

let request = axios.create({
    baseURL: process.env.VUE_APP_BASE_API
    // baseURL: "https://localhost:7149/API/"
});

export interface responseDto<T> {
    statusCode: number;
    data: T,
    succeeded: boolean,
    errors: string,
    extras: any,
    timestamp: number
}
export type TresponseDto = responseDto<any>


// 添加请求拦截器
request.interceptors.request.use(function (config) {
    if (config.method == "post")
        config.headers!['content-type'] = 'application/json;charset=utf-8';
    config.headers!.Authorization = `Bearer ${window.localStorage.getItem("access-token")}`;
    config.headers!["X-Authorization"] = `Bearer ${window.localStorage.getItem("x-access-token")}`;

    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
request.interceptors.response.use(function (response: AxiosResponse) {
    let userStore = useUserStore();
    let oldStoreStore = useOldStoreStore();

    // 处理规范化结果带 `errors` 问题
    const result = response.data;

    if (!result.succeeded) {
        // ElMessage.error(result.errors)
        ElMessage({
            message: result.errors,
            type: 'error',
            center: true,
            duration: 1000,
            // onClose: () => {
            //     store.commit(DefaultChoice, 'userInfo');
            //     router.push("/practiceList");
            // }
        })
        throw new Error(result.errors);
    }

    // 通过请求报文头读取新的 `token` 和 `刷新 token`
    var accessToken = response.headers["access-token"];
    var xAccessToken = response.headers["x-access-token"];

    // 处理无效 `token` 问题
    if (accessToken === "invalid_token") {
        window.localStorage.removeItem("access-token");
        window.localStorage.removeItem("x-access-token");
        oldStoreStore.DefaultChoice = 'userInfo';
        userStore.quit();
        router.push("/practiceList");
    }
    // 处理自动刷新
    else if (xAccessToken && accessToken && accessToken !== "invalid_token") {
        window.localStorage.setItem("access-token", accessToken);
        window.localStorage.setItem("x-access-token", xAccessToken);
    }

    return result;
}, function (error) {
    let oldStoreStore = useOldStoreStore();
    if ([401, 403].includes(error.response.status)) {
        oldStoreStore.DefaultChoice = 'userInfo';
        router.push("/practiceList");
    }

    // 对响应错误做点什么
    return Promise.reject(error);
});

export async function Get<T>(url: string):Promise<T> {
    let response = await request.get(url);
    return Promise.resolve(response as any);
}

export default request;